import React, { useState, useRef, useEffect } from 'react';
import { Button, Grid, Container, Typography, IconButton } from '@mui/material';
import { FaExchangeAlt, FaTrash } from 'react-icons/fa';
import { styled } from '@mui/system';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Fade, Paper, CardHeader, } from '@mui/material';
import {Card, Carousel} from 'react-bootstrap';

// Styles
const primaryColor = '#3f51b5';
const hoverColor = 'green';

const globalFontFamily = {
    fontFamily: 'Roboto, sans-serif',
};

const StyledButton = styled(Button)(({ theme }) => ({
    ...globalFontFamily,
    textTransform: 'none',
    transition: '0.3s',
    '&:hover': {
        background: hoverColor,
        color: 'white',
    }
}));

const StyledTypography = styled(Typography)({
    ...globalFontFamily,
    color: 'gray',
    marginBottom: '1rem',
    fontWeight: 500,
});

const StyledPaper = styled(Paper)({
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    border: `2px solid ${primaryColor}`,
    width: '100%',
});

const HiddenInput = styled('input')({
    display: 'none',
});

interface ProductCaptureProps {
    images: string[] | null;
    barcodeString: string;
    setImages: React.Dispatch<React.SetStateAction<string[] | null>>;
}
const ProductCapture: React.FC<ProductCaptureProps> = ({ images, setImages, barcodeString }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [imageResolutions, setImageResolutions] = useState<{ width: number; height: number }[]>([]);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'failed'>('idle');
    const [uploadError, setUploadError] = useState<string | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('environment');
    const [imageResolution, setImageResolution] = useState<{ width: number; height: number } | null>(null);

    
    const [isCameraAvailable, setIsCameraAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            return;
        }    
        startVideo();
    
        // Cleanup function for useEffect
        return () => {
            if (stream) {
                let tracks = stream.getTracks();  // get all tracks from the MediaStream
                tracks.forEach(track => track.stop());  // stop each track to stop the camera
            }
        };
    }, [videoRef]); 

    useEffect(() => {
        logImageResolutions();
    }, [images]);
    

    let stream: MediaStream | null = null;
    const startVideo = async () => {
        try {
            stream = await navigator.mediaDevices.getUserMedia({ 
                video: { 
                    facingMode,
                    width: { ideal: 10000 }, 
                    height: { ideal: 10000 }, 
                } 
            });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                setIsCameraAvailable(true);
            }
        } catch (err) {
            console.error("Error accessing the camera:", err);
            setIsCameraAvailable(false);
        }
    };

    const captureImageFromVideo = () => {
        const canvas = canvasRef.current!;
        const video = videoRef.current!;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d')!.drawImage(video, 0, 0);
        
        const imageSrc = canvas.toDataURL('image/jpeg', 1); 
        addImage(imageSrc);
    };
    

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
        const reader = new FileReader();

        setUploadStatus('uploading');
        
        reader.onload = e => {
            const imgSrc = e.target!.result as string;
            addImage(imgSrc);
            setUploadStatus('success');
        };

        reader.onerror = () => {
            setUploadStatus('failed');
            setUploadError(reader.error?.message || 'Failed to upload image.');
        };

        reader.readAsDataURL(file);
    } else {
        setUploadStatus('failed');
        setUploadError('No file selected.');
    }
};

    
    

const logImageResolutions = () => {
    if (!images) return;  // Exit if images is null or undefined

    const newResolutions: React.SetStateAction<{ width: number; height: number; }[]> = [];
    images.forEach((imageUrl, idx) => {
        const img = new Image();
        img.onload = () => {
            newResolutions.push({ width: img.width, height: img.height });
            if (newResolutions.length === images.length) {
                setImageResolutions(newResolutions);
            }
        };
        img.src = imageUrl;
    });
};

    

    const addImage = (imageSrc: string) => {
        if (images && images.length >= 1) {
            alert('Maximum of 1 images can be uploaded!');
            return;
        }
        setImages(prevImages => (prevImages ? [...prevImages, imageSrc] : [imageSrc]));
    };

    const removeImage = (index: number) => {
        setImages(prevImages => {
            const newImages = [...(prevImages || [])];
            newImages.splice(index, 1);
            return newImages;
        });
    };

    const switchCamera = () => {
        // Toggle the camera
        const newFacingMode = facingMode === 'user' ? 'environment' : 'user';
        setFacingMode(newFacingMode);
    
        // Stop existing tracks before restarting the video to switch the camera
        const tracks = (videoRef.current?.srcObject as MediaStream)?.getTracks();
        tracks?.forEach(track => track.stop());
    
        // Start the video with the new camera
        startVideo();
    };
    

    return (
        <Container disableGutters>
            <Grid container alignItems="center" justifyContent="center">

                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h5" style={{ textDecoration: 'underline', margin:"1rem 0" }}>
                        Product Capture
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center', padding:"0" }}>
                    <div className='product-btn-group' style={{display:"flex", flexDirection:"row", marginBottom:"1rem"}}>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <StyledButton
                                variant="contained"
                                onClick={() => fileInputRef.current!.click()}
                                style={{width:"fit-content"}}
                                // camera icon
                            >
                                📸
                            </StyledButton>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <StyledButton
                                variant="contained"
                                startIcon={<FaExchangeAlt style={{margin:"0"}}/>}
                                onClick={switchCamera}
                                style={{padding:"0", height:"100%"}}
                            >
                            </StyledButton>
                        </Grid>
                    </div>
                    <HiddenInput
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                    />
                </Grid>

                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding:"0", marginBottom:"1rem", height:"80vh", maxWidth:"100vw" }}>
                    <StyledPaper>
                        <video ref={videoRef} autoPlay playsInline style={{height:"100%", maxWidth:"100%"}}></video>
                        <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
                    </StyledPaper>
                </Grid>

                <Grid item xs={12} style={{ textAlign: 'center' }}>
                <StyledButton 
                    variant="contained" 
                    color="primary" 
                    onClick={captureImageFromVideo}
                    disabled={!isCameraAvailable} 
                >
                    Capture
                </StyledButton>
                    {/* <Button onClick={logImageResolutions}>Log Image Resolutions</Button> */}

                </Grid>

                <Grid className='carousel-class' item xs={12} style={{ textAlign: 'center', padding:"1.5rem" }}>
                {images && images.length > 0 ? (
                    <Carousel 
                        key={images.length}
                        prevIcon={<span className="carousel-control-prev-icon" />}
                        nextIcon={<span className="carousel-control-next-icon" />}
                    >
                        {images.map((src, idx) => (
                            <Carousel.Item key={idx}>
                                <IconButton
                                    color="error"
                                    style={{ position: 'absolute', top: '0', left: '0', zIndex: 1000 }}
                                    onClick={() => removeImage(idx)}
                                >
                                    <FaTrash />
                                </IconButton>
                                <img
                                    className="d-block"
                                    src={src}
                                    alt={`product-${idx}`}
                                    style={{maxWidth:"100%", height:"auto", margin:"auto"}}
                                />

                                <Carousel.Caption>
                                    Image {idx + 1}
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                ) : (
                    <Typography align="center" style={{ fontWeight: 400 }}>No images to display</Typography>
                )}

                </Grid>
            </Grid>
        </Container>
    );
};

export default ProductCapture;