import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Login from './pages/Login';
import EnrollCapture from './pages/EnrollCapture';
import Camera from './pages/camera';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  direction: 'ltr',  // Explicitly set the direction to Left-to-Right
});


function App() {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/enroll-capture" element={<EnrollCapture />} />
          <Route path="/enroll-capture-image" element={<Camera />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
