import { BarcodeScanner } from "dynamsoft-javascript-barcode";
import React from 'react';
import './VideoDecode.css'

interface VideoDecodeProps {
    setBarcodeString: React.Dispatch<React.SetStateAction<string>>;
    barcodeAlreadyDetected: boolean;
    setBarcodeAlreadyDetected: React.Dispatch<React.SetStateAction<boolean>>;
    barcodeDetected: Array<string>;
    processed_barcode_type: string;
    setProcessed_barcode_type: React.Dispatch<React.SetStateAction<string>>;
    setEnrollable: React.Dispatch<React.SetStateAction<boolean>>;
}

const barcode_format = ["EAN_8", "EAN_13", "UPC_A", "UPC_E"];
class VideoDecode extends React.Component<VideoDecodeProps> {
        
    pScanner: Promise<BarcodeScanner>|null = null;
    elRef: React.RefObject<HTMLDivElement> = React.createRef();
    async componentDidMount() {
        try {
            const scanner = await (this.pScanner = BarcodeScanner.createInstance());
            // await this.configureVideoSettings(scanner);
            // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode. 
            if(scanner.isContextDestroyed()) return;
            await scanner.setUIElement(this.elRef.current!);
            // Should judge if scanner is destroyed after 'await', as in development React runs setup and cleanup one extra time before the actual setup in Strict Mode. 
            if(scanner.isContextDestroyed()) return;
            scanner.onFrameRead = results => {
                for (let result of results) {
                    if (barcode_format.includes(result.barcodeFormatString)) {
                        if(this.props.barcodeDetected.includes(result.barcodeText)) {
                            this.props.setBarcodeAlreadyDetected(true);
                            break;
                        }
                        this.props.setEnrollable(true);
                        this.props.setBarcodeString(result.barcodeText);
                        this.props.setProcessed_barcode_type(result.barcodeFormatString);
                        break;
                    }
                    console.log(result.barcodeText);
                }
            };
            scanner.onUniqueRead = (txt, result) => {
                console.log("text and result", txt, result)
            }
            await scanner.open();
        } catch (ex: any) {
            if (ex.message.indexOf("network connection error")) {
                // let customMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
                console.log("Error happened when creating BarcodeScanner instance:", ex.message);
                alert("Error happened when creating BarcodeScanner instance");
            }
            throw ex;
        }
    }
    async componentWillUnmount() {
        if (this.pScanner) {
            (await this.pScanner).destroyContext();
            console.log('BarcodeScanner Component Unmount');
        }
    }

    // async configureVideoSettings(scanner: any) {
    //     console.log("scanner in configureVideoSettings", scanner)
    //     await scanner.dce.setResolution(640, 480);
    //     await scanner.updateRuntimeSettings('speed');
    //     const runtimeSettings = await scanner.getRuntimeSettings();
    //     runtimeSettings.expectedBarcodesCount = 1;
    //     runtimeSettings.furtherModes.grayscaleTransformationModes = [2, 0, 0, 0, 0, 0, 0, 0];
    //     await scanner.updateRuntimeSettings(runtimeSettings);
    // }
    shouldComponentUpdate() {
        // Never update UI after mount, dbrjs sdk use native way to bind event, update will remove it.
        return false;
    }
    render() {
        return (
            <div ref={this.elRef} className="component-barcode-scanner">
                <svg className="dce-bg-loading" viewBox="0 0 1792 1792"><path d="M1760 896q0 176-68.5 336t-184 275.5-275.5 184-336 68.5-336-68.5-275.5-184-184-275.5-68.5-336q0-213 97-398.5t265-305.5 374-151v228q-221 45-366.5 221t-145.5 406q0 130 51 248.5t136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5q0-230-145.5-406t-366.5-221v-228q206 31 374 151t265 305.5 97 398.5z"></path></svg>
                <svg className="dce-bg-camera" viewBox="0 0 2048 1792"><path d="M1024 672q119 0 203.5 84.5t84.5 203.5-84.5 203.5-203.5 84.5-203.5-84.5-84.5-203.5 84.5-203.5 203.5-84.5zm704-416q106 0 181 75t75 181v896q0 106-75 181t-181 75h-1408q-106 0-181-75t-75-181v-896q0-106 75-181t181-75h224l51-136q19-49 69.5-84.5t103.5-35.5h512q53 0 103.5 35.5t69.5 84.5l51 136h224zm-704 1152q185 0 316.5-131.5t131.5-316.5-131.5-316.5-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5z"></path></svg>
                <div className="dce-video-container"></div>
                <div className="dce-scanarea">
                    <div className="dce-scanlight"></div>
                </div>
                <div className="div-select-container">
                    <select className="dce-sel-camera"></select>
                    <select className="dce-sel-resolution"></select>
                </div>
            </div>
        );
    }
}

export default VideoDecode;