import React from "react";
import NavBarComponent from "../containers/NavBar/NavBar";
import BarcodeImage from "../containers/BarcodeImage/BarcodeImage";
import ProductCapture from "../containers/ProductCapture/ProductCapture";
import { Container } from "react-bootstrap";
import Button from "@mui/material/Button";
import { submitImages } from "../APIs/apis";
import { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import logo from '../Assets/Logo.jpeg';
import { getUserInfo } from "../APIs/LoginAPI";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import jwt_decode, { JwtPayload } from 'jwt-decode';
import '../style/EnrollCapture.css';

type Token = {
    exp: number;
};


const EnrollCapture: React.FC = () => {
    const navigate = useNavigate();
    const [BarcodeImageSrc, setBarcodeImageSrc] = useState<string | null>(null);
    const [enrollable, setEnrollable] = useState<boolean>(false); 
    const [barcodeAlreadyDetected, setBarcodeAlreadyDetected] = useState<boolean>(false);
    const [processed_barcode_type, setProcessed_barcode_type] = useState<string>("");
    const [barcodeString, setBarcodeString] = useState<string>("");
    const [productImages, setProductImages] = useState<string[] | null>(null);
    const [username, setUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState({ title: '', message: '' });
    const [uploadFailed, setUploadFailed] = useState<boolean>(false);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        // If you want to reset the entire component's state, you can do so here.
        setBarcodeImageSrc(null);
        setBarcodeAlreadyDetected(false);
        setProcessed_barcode_type("");
        setBarcodeString("");
        setProductImages(null);
    };

    const handleImageSubmit = async () => {
        if ( !productImages || productImages.length === 0 || processed_barcode_type === "" || barcodeString === "") {
            console.error("Some required data is missing.");
            return;
        }
        setLoading(true);
        try {
            let bear_token = await Cookies.get('access_token');
            if (!bear_token) {
                console.error("No access token found.");
                return;
            }  
            // const barcodeImageFile = await urlToFile(BarcodeImageSrc, 'barcodeImage.png');
            const productImageFiles = await Promise.all(productImages.map((src, index) => urlToFile(src, `productImage${index}.jpg`)));
            console.log("productImageFiles", productImageFiles);
            const response = await submitImages(bear_token, processed_barcode_type, barcodeString, productImageFiles);
            
            if (response === 201) {
                setDialogContent({ title: 'Success', message: 'Product enrolled!' });
            } else {
                setDialogContent({ title: 'Failed', message: 'Upload failed!' });
            }
            setOpenDialog(true);
        } catch (error) {
            console.error("Error in image submission:", error);
            setDialogContent({ title: 'Failed', message: 'Upload failed!' });
            setOpenDialog(true);
        } finally {
            setLoading(false);
        }
    };
  
    const urlToFile = async (url: string, filename: string) => {
        const response = await fetch(url);
        const data = await response.blob();
        const metadata = { type: data.type };
        const file = new File([data], filename, metadata);
        return file;
    };

    useEffect(() => {
        const fetchUserinfo = async () => {
            let bear_token = Cookies.get('access_token');  
    
            while (!bear_token) {
                await new Promise(resolve => setTimeout(resolve, 1000));
                bear_token = Cookies.get('access_token'); 
                console.log("bear_token now", bear_token);
            }
    
            if (bear_token) {
                const userinfo_Data = await getUserInfo(bear_token);
                if (userinfo_Data) {
                    setUsername(userinfo_Data.username);
                    console.log("userinfo_Data", userinfo_Data);
                }
            }
        };
    
        fetchUserinfo();
    }, []);
    
    useEffect(() => {
        
        const isAccessTokenValid = (access_token: Token) => {
            const currentTime = new Date().getTime() / 1000;
            const expireTime = access_token.exp;
            return currentTime < expireTime;
        }

        const checkTokenValidity = async () => {
            const bear_token = Cookies.get('access_token');
            let isTokenValid = true;
            if (bear_token){
                isTokenValid = isAccessTokenValid(jwt_decode<Token>(bear_token));
                console.log("token valid?", isTokenValid);
            }
            if (!bear_token || (!isTokenValid)) {
                // navigate("/");  
                return;
            }
    
            const userinfo_Data = await getUserInfo(bear_token);
            if (userinfo_Data) {
                setUsername(userinfo_Data.username);
            }
        };
    
        checkTokenValidity();
        
        const tokenCheckInterval = setInterval(checkTokenValidity, 5000);  // Check every 5 seconds
    
        return () => {
            clearInterval(tokenCheckInterval);
        };
    }, [navigate]);
    


    useEffect(() => {
        if(barcodeString.length < 5 && !productImages) {
            setEnrollable(false);
        }else{
            setEnrollable(true);
        }
    },[barcodeString, productImages]);

    return (
        <Container fluid style={{padding: 0, height: "90vh", display: "flex", flexDirection: "column"}}>
            <NavBarComponent username={username}/>
            <BarcodeImage setEnrollable={setEnrollable} processed_barcode_type={processed_barcode_type} setProcessed_barcode_type={setProcessed_barcode_type} imageSrc={BarcodeImageSrc} setImageSrc={setBarcodeImageSrc} barcodeString={barcodeString} setBarcodeString={setBarcodeString} barcodeAlreadyDetected={barcodeAlreadyDetected} setBarcodeAlreadyDetected={setBarcodeAlreadyDetected} />
            {barcodeString.length >= 5 ? <ProductCapture images={productImages || []} setImages={setProductImages} barcodeString={barcodeString}/> : null }
            {loading && 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh' }}>
                <CircularProgress />
            </div>
        }

        {uploadFailed && 
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '5vh', margin: '1rem 0' }}>
                <Alert severity="error">Upload failed!</Alert>
            </div>
        }

        <div className="submit-button" style={{ justifyContent: "center", display: "flex", margin: "1rem 0 1rem 0" }}>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                className="submit-btn mt-3"
                style={{ width: "30vw", maxWidth: "20rem" }}
                onClick={handleImageSubmit}  
                disabled={loading || !enrollable}  
            >
                Enroll
            </Button>
        </div>
            <div style={{visibility:"hidden"}}>hidden</div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogContent.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>

        
    );    
};

export default EnrollCapture;
