import axios from 'axios';
import Cookies from 'js-cookie';
const url='https://skynet88-drstrange.andrew.cmu.edu/api/auth/login/'
// export const loginUser = async (username, password) => {
//     const res = await axios.post(`http://128.2.25.96:8000/auth/login/token/`, {
//         username: username,
//         password: password,
//     });
//     return res;
// };

const setCookies = (access:string, refresh:string) => {
    Cookies.set('access_token', access, { sameSite: 'strict' });
    Cookies.set('refresh_token', refresh, {  sameSite: 'strict' });
}

export const loginUser = async (username:string, password:string) => {
    const res = await axios.post(
        `${url}token/`,
        {
            username: username,
            password: password,
        },
        {
            validateStatus: function (status) {
                return status >= 200 && status < 300; // default
            },
        }
    );
    return res;
};



export const refreshToken = async (refresh_token:string) =>{
    console.log("trying to refresh token")
    const res = await axios.post(`${url}refresh/`, {
        refresh: refresh_token
    });
    if (res.status !== 200) {
        return null;
    }
    const { access, refresh } = res.data;
    setCookies(access, refresh);
    console.log("Cookies are set at refreshToken() LoinAPI.jsx");
    return res.data;
    /* res.data format: {
        "refresh": .....
        "access": ......
    } */
}

export const getUserInfo = async (bear_token:string) => {
    try {
        const res = await axios.get(`https://skynet88-drstrange.andrew.cmu.edu/api/auth/userinfo/`, {
            headers: {
                Authorization: `Bearer ${bear_token}`
            }
        });
        if (res.status !== 200){
            return null;
        }
        return res.data;
        /* format:{
            username: 'jason',
            profile_image_url: '
        } */

    } catch (error) {
        console.error(error);
    }
}


