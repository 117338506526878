import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Badge } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Cookies from 'js-cookie';
import logo from '../../Assets/Logo.jpeg';

interface NavBarInterface {
  username: string;
}

const NavBarComponent: React.FC<NavBarInterface> = ({ username }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    console.log("logout");
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    navigate('/');
  };

  return (
    <AppBar position="static" color="primary" style={{marginBottom:"2rem", padding:"1.5vh 0.5vw"}}>
      <Toolbar style={{ display: 'flex', justifyContent:"space-between" }}>
        
        {/* Logo & Brand Name */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img alt="Logo" src={logo} style={{ width: '10vw', maxHeight: '15vh' }} />
        </div>
        <Typography style={{ fontSize: '4.5vw' }}>
            {process.env.REACT_APP_TITLE ? process.env.REACT_APP_TITLE : "123"}
          </Typography>
        {/* User Info */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton 
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleUserClick}
            color="inherit"
          >
            <Badge color="secondary">
              <AccountCircle style={{ fontSize: '6vw' }} />
            </Badge>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem style={{ fontSize: '2.5vw' }} onClick={handleClose}>{username}</MenuItem>
            <MenuItem style={{ fontSize: '2.5vw' }} onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBarComponent;
