import axios from 'axios';

const url = "https://skynet88-drstrange.andrew.cmu.edu/api/dataupload/";


export const submitImages = async (bear_token:string, processed_barcode_type: string, barcodeString: string, productCaptures: Array<File>) => {
    try {
        const formData = new FormData();
        formData.append("barcode_type", processed_barcode_type);
        formData.append("barcode", barcodeString);
        productCaptures.forEach(capture => {
            formData.append("product_images", capture);
        });

        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${bear_token}`
            }
        });
        console.log("response", response);
        return response.status;

    } catch (error) {
        console.error("Error submitting images:", error);
        throw error;  
    }
};

export const getImageInfo = async (bear_token:string, barcodeString: string) => {
    try {
        const response = await axios.get(`${url}?barcode=${barcodeString}`, {
            headers: {
                Authorization: `Bearer ${bear_token}`
            }
        });
        console.log("response.data", response);
        return response.data
    } catch (error) {
        console.error("Error getting image info:", error);
        throw error;
    }
}
