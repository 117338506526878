import React, { ChangeEvent, Component, useRef } from 'react';
import { BarcodeReader } from "dynamsoft-javascript-barcode";
import { Button, Fade } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import './ImageDecode.css';

interface ImageDecodeProps {
  setImageSrc: React.Dispatch<React.SetStateAction<string | null>>;
  bShowScanner: boolean;
  bShowImgDecode: boolean;
  showScanner: () => void;
  showImgDecode: () => void;
}

export default class ImgDecode extends Component<ImageDecodeProps> {
  fileInputRef = React.createRef<HTMLInputElement>(); 

  constructor(props: ImageDecodeProps) {
    super(props);
    this.handleImageCapture = this.handleImageCapture.bind(this);
  }
  
  pReader: Promise<BarcodeReader> = BarcodeReader.createInstance();

  decodeImg = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const reader = await this.pReader;
      let results = await reader.decode(e.target.files![0]);
      for (let result of results) {
        alert(result.barcodeText);
      }
      if (!results.length) { alert('No barcode found'); }
    } catch (ex: any) {
      if (ex.message.indexOf("network connection error")) {
        // let customMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
        console.log("Error happened when creating BarcodeReader instance:", ex.message);
      }
      throw ex;
    }
    e.target.value = '';
  }

  async componentWillUnmount() {
    if (this.pReader) {
      (await this.pReader).destroyContext();
      console.log('ImgDecode Component Unmount');
    }
  }

  async handleImageCapture(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
            this.props.setImageSrc(e.target?.result as string);
        };
        reader.readAsDataURL(file);
    }
  }

  render() {
    const { bShowScanner, bShowImgDecode, showScanner, showImgDecode } = this.props;
    return (
      <>
        <Button 
            variant="contained" 
            onClick={showScanner}
            style={bShowScanner ? { backgroundColor: '#003366' } : {}}
        >
            <FontAwesomeIcon icon={faVideo} />
        </Button>

        <Button
            variant="contained"
            onClick={() => {
              this.fileInputRef.current!.click()
              showImgDecode()
            }}
            style={bShowImgDecode ? { backgroundColor: '#003366' } : {}}
            // camera icon
        >
            📸
            <input
                ref={this.fileInputRef}
                type="file"
                accept="image/*"
                onChange={this.handleImageCapture}
                hidden 
            />
        </Button>

      </>
    );
  }
}
