import React, { useState, useEffect } from 'react';
import { BarcodeReader } from 'dynamsoft-javascript-barcode';
import {Paper} from '@mui/material';
import ImgDecode from '../ImageDecode/ImageDecode';
import VideoDecode from '../VideoDecode/VideoDecode';
import EditIcon from '@mui/icons-material/Edit';
import Cookies from 'js-cookie';
import {getImageInfo} from '../../APIs/apis';
import {
  Container,
  Grid,
  Button,
  ImageList,
  ImageListItem,
  TextField,
  Alert,
  Fade,
  Typography,
  Collapse,
  Card,
  CardContent, 
  CardHeader, 
  IconButton
} from '@mui/material';
// If there are no official typings, define a simple interface to avoid TS errors:
BarcodeReader.license = process.env.REACT_APP_DYNAMSOFT_LICENSE ? process.env.REACT_APP_DYNAMSOFT_LICENSE : "";
BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode/dist/";
interface IDynamsoftScanner {
    onFrameRead: (results: { BarcodeText: string }[]) => void;
    onNewCodeRead: (txt: string, result: { BarcodeText: string }) => void;
    open: () => Promise<void>;
    show: () => Promise<void>;
    updateVideoSettings: (settings: { video: { src: string } }) => void;
}

interface BarcodeImageProps {
    imageSrc: string | null;
    setImageSrc: React.Dispatch<React.SetStateAction<string | null>>;
    barcodeString: string;
    setBarcodeString: React.Dispatch<React.SetStateAction<string>>;
    barcodeAlreadyDetected: boolean;
    setBarcodeAlreadyDetected: React.Dispatch<React.SetStateAction<boolean>>;
    processed_barcode_type: string;
    setProcessed_barcode_type: React.Dispatch<React.SetStateAction<string>>;
    setEnrollable: React.Dispatch<React.SetStateAction<boolean>>;
}

const BarcodeImage: React.FC<BarcodeImageProps> = ({ imageSrc, setImageSrc, barcodeString, setBarcodeString, barcodeAlreadyDetected, setBarcodeAlreadyDetected, processed_barcode_type, setProcessed_barcode_type, setEnrollable }) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [showBarcodeString, setShowBarcodeString] = useState<boolean>(false);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [bShowScanner, setbShowScanner] = useState<boolean>(false);
    const [bShowImgDecode, setbShowImgDecode] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const barcode_format = ["EAN_8", "EAN_13", "UPC_A", "UPC_E"];
    const [barcode_detected, setBarcodeDetected] = useState<Array<string>>([]);
    let barcode_capture_already_check = false;

    const darkOverlayStyle = {
        position: 'fixed' as 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        zIndex: 999,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    useEffect(() => {
        const handleCheckFromServer = async (barcode_text: string) => {
            try {
                let bear_token = Cookies.get('access_token');
                if (!bear_token) {
                    console.log("No access token found");
                    return null;
                }
                const checkfromserver = await getImageInfo(bear_token, barcode_text);
                return checkfromserver;
            } catch (error) {
                console.error("Error checking from server:", error);
                return null;
            }
        }
    
        const handleDecoding = async (scanner: any) => {
            try {
                const results = await scanner.decode(imageSrc);
                if (results.length === 0) {
                    setShowAlert(true);
                    return;
                }
                for (let i = 0; i < results.length; i++) {
                    let result = results[i];
                    if (barcode_format.includes(String(result.barcodeFormatString))) {
                        setBarcodeString(result.barcodeText);
                        setProcessed_barcode_type(result.barcodeFormatString);
                        setCollapsed(true);
                        if (barcode_capture_already_check === true){
                            const serverResponse = await handleCheckFromServer(result.barcodeText); 
                            if (serverResponse.status !== 0) {
                                setBarcodeAlreadyDetected(true);
                                break;
                            }
                        }
                        setbShowScanner(false);
                        setShowBarcodeString(true);
                        setShowAlert(false);
                        setBarcodeAlreadyDetected(false);
                        setCollapsed(true);
                        break;

                    }
                    if (i === results.length - 1) {
                        setShowBarcodeString(false);
                        setShowAlert(true);
                    }
                }
            } catch (err) {
                console.log("Error:", err);
                setShowBarcodeString(false);
                setShowAlert(true);
            }
        }
    
        if (imageSrc) {
            setShowBarcodeString(false);
            setbShowScanner(false);
            BarcodeReader.createInstance()
                .then(scanner => {
                    setRuntimeSettingsForSpeed(scanner);
                    return handleDecoding(scanner);
                });
        }
        if (barcodeString && barcodeString.trim() !== "") {
            setbShowScanner(false);
        }
    }, [imageSrc, barcodeString, setBarcodeString]);
    

    useEffect(() => {
        // if video can be loaded (getusermedia), clear imageSrc and setbShowImgDecode to false
        if (bShowScanner) {
            setImageSrc(null);
            setbShowImgDecode(false);
            setShowBarcodeString(false);
            setBarcodeString("");
        }
        if (bShowImgDecode) {
            setbShowScanner(false);
            setShowBarcodeString(false);
            setBarcodeString("");
        }
    }, [bShowScanner,bShowImgDecode]);

  async function setRuntimeSettingsForSpeed(reader: any) {
    await reader.updateRuntimeSettings('speed');
    const runtimeSettings = await reader.getRuntimeSettings();
    runtimeSettings.expectedBarcodesCount = 1; // expect only 1 barcode per scan
    runtimeSettings.furtherModes.grayscaleTransformationModes = [2, 0, 0, 0, 0, 0, 0, 0]; // the first mode set to 2 (gray equalization)
    await reader.updateRuntimeSettings(runtimeSettings);
  }


    const handleImageCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target?.result as string);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
  
      // Check if the value is an empty string or contains only numbers.
      if (newValue === '' || /^[0-9]+$/.test(newValue)) {
          setBarcodeString(newValue);
      } else {
          // This line will force the input to revert back to the last valid value
          e.target.value = barcodeString;
      }
    };
    
    const showScanner = () => {
      if (bShowScanner) {
        setbShowScanner(false);
        setbShowImgDecode(true);
      }else{
        setbShowScanner(true);
        setbShowImgDecode(false);
      }
    }

    const showImgDecode = () => {
        if(bShowImgDecode){
            setbShowImgDecode(false);
            setbShowScanner(false);
        }else{
            setbShowImgDecode(true);
            setbShowScanner(false);
        }
    }
  
  return (
        <Container className='BarcodeImage-container' style={{ padding: 0, margin: 0, maxWidth:"100%", width:"100%" }}>

            {/* Camera Button */}
            <Grid container justifyContent="center">
                <Grid item xs={12} container justifyContent="space-around">
                    <ImgDecode setImageSrc={setImageSrc} showImgDecode={showImgDecode} bShowImgDecode={bShowImgDecode} bShowScanner={bShowScanner} showScanner={showScanner} />
                </Grid>
                <Fade in={true} timeout={1000}>
                    <Paper elevation={imageSrc ? 3 : 1} style={{ display: "flex", justifyContent: "center", padding: "1rem", marginTop: "2rem", border: imageSrc ? '2px solid #3f51b5' : '1px solid #e0e0e0', width: "90%" }}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={6} container direction="column" alignItems="flex-start">
                                <Typography variant="h6" style={{ fontSize: "1.25rem" }}>Barcode Image</Typography>
                                <Typography variant='h6' style={{ fontSize: "0.75rem", display:(imageSrc || bShowScanner) ? "none":"", width:"max-content" }}>Take photo or upload image</Typography>
                            </Grid>
                            <Grid item xs={6} container alignItems="center" justifyContent="flex-end" direction="row">
                                <Button variant="contained" style={{ padding: 0 }} onClick={() => setCollapsed(!collapsed)}>
                                    <Fade in={!collapsed} timeout={1000}>
                                        <Typography style={{ display: collapsed ? 'none' : 'block' }}>▲</Typography>
                                    </Fade>
                                    <Fade in={collapsed} timeout={1000}>
                                        <Typography style={{ display: !collapsed ? 'none' : 'block' }}>▼</Typography>
                                    </Fade>
                                </Button>
                            </Grid>

                            <Collapse in={!collapsed}>
                                <ImageList cols={1} gap={4} style={{ marginTop: 20 }}>
                                    <ImageListItem>
                                        {imageSrc ? (
                                            <img src={imageSrc} alt="Barcode" style={{ width: '100%', height: 'auto' }} />
                                        ) : (
                                            <div style={{ width: '100%', backgroundColor: 'grey' }}></div>
                                        )}
                                    </ImageListItem>
                                </ImageList>
                            </Collapse>
                            <Grid item xs={12}>
                                {bShowScanner && <VideoDecode setEnrollable={setEnrollable} processed_barcode_type={processed_barcode_type} setProcessed_barcode_type={setProcessed_barcode_type} setBarcodeString={setBarcodeString} barcodeAlreadyDetected={barcodeAlreadyDetected} setBarcodeAlreadyDetected={setBarcodeAlreadyDetected} barcodeDetected={barcode_detected}/>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Fade>
            </Grid>

            {(showAlert || barcodeAlreadyDetected) && 
                <>
                    <Fade in={showAlert}>
                        <Alert variant="outlined" severity="error" onClick={()=>setShowAlert(false)} style={{ marginTop: 10 }}>
                            Barcode not detected!
                        </Alert>
                    </Fade>
                    <Fade in={barcodeAlreadyDetected}>
                        <Alert variant="outlined" severity="error" onClick={()=>setShowAlert(false)} style={{ marginTop: 10 }}>
                            Barcode already captured!
                        </Alert>
                    </Fade>
                </>
            }

            {/* Text Area */}
                <div className='barcode-string-holder' style={{display:"flex", justifyContent:"center"}}>
                    <Fade in={true} timeout={2000}>
                        <Card variant="outlined" style={{ marginTop: 20, width:"90%", justifyContent:"center", borderRadius:"0.4rem" }}>
                            <CardHeader 
                                title="Barcode Text"
                                action={
                                    <IconButton onClick={() => setIsEditing(true)}>
                                        <EditIcon />
                                    </IconButton>
                                }
                                style={{padding:"0 1rem"}}
                            />
                            <CardContent>
                                {isEditing ? (
                                    <TextField
                                        fullWidth
                                        value={barcodeString}
                                        onChange={(e) => setBarcodeString(e.target.value)}
                                        onBlur={() => setIsEditing(false)}
                                        autoFocus
                                    />
                                ) : (
                                    <Typography variant="body1">
                                        {barcodeString}
                                    </Typography>
                                )}
                            </CardContent>
                        </Card>
                    </Fade>
                </div>

        </Container>
    );
};

export default BarcodeImage;
