import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../APIs/LoginAPI';
import logo from '../Assets/Logo.jpeg';
import Cookies from 'js-cookie';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import axios, { AxiosResponse } from 'axios';
import { Alert } from 'react-bootstrap';
import { Button, TextField, Container } from '@mui/material';
import { styled } from '@mui/system';
import '../style/Login.css';

type Token = {
    exp: number;
};
const StyledButton = styled(Button)({
    '&:hover': {
        backgroundColor: '#0056b3', 
    },
});

const setCookies = (access: string, refresh: string) => {
    Cookies.set('access_token', access, { sameSite: 'strict' });
    Cookies.set('refresh_token', refresh, {  sameSite: 'strict' });
}

const isAccessTokenValid = (access_token: Token) => {
    const currentTime = new Date().getTime() / 1000;
    const expireTime = access_token.exp;
    return currentTime < expireTime;
}

function Login() {
    const [ username, setUsername ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ errorMessage, setErrorMessage ] = useState<string>('');
    const navigate = useNavigate();
    
    useEffect(() => {
        const AccToken = Cookies.get('access_token');
        if (AccToken) {
          try {
            const AccDecoded = jwt_decode<Token>(AccToken);
            if (isAccessTokenValid(AccDecoded)){
                navigate("/enroll-capture");
            }
          } catch (err) {
            console.error(err);
          }
        }
      }, []);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'username') {
            setUsername(event.target.value);
        } else {
            setPassword(event.target.value);
        }
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setErrorMessage('');

        if (username === '' || password === '') {
            setErrorMessage('Please enter username and password');
            return;
        }
        if (username.length < 3 || password.length < 3) {
            setErrorMessage('Username and password must be at least 3 characters long');
            return;
        }
        navigate('/enroll-capture');//mock navigate, remove later
        
        try {
            const res: AxiosResponse = await loginUser(username, password);
            if (res.status === 200) {
                const { access, refresh } = res.data;
                if (!access || !refresh) {
                    setErrorMessage('Error logging in');
                    return;
                }
                setCookies(access, refresh);
                navigate('/enroll-capture');
            }
            else{
                console.log(res);
                setErrorMessage('Invalid username or password');
            }

        }
        catch (error: any) {
            if (error.response) {
                console.log(error.response.status);
                if (error.response.status === 401) {
                    setErrorMessage('Invalid username or password');
                } else {
                    setErrorMessage('Something went wrong, please try again');
                }
            } else if (error.request) {
                setErrorMessage('Cannot login, check your internet environment')
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
        
    };
    const getErrorMessage = () => {
        return errorMessage && <Alert variant="danger">{errorMessage}</Alert>;
    }

    return (
        <Container className="login-container">
            <div className="login-navbar" style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginBottom: "1rem", alignItems: "center" }}>
                <img alt="Logo" src={logo} width="90" height="70" className="d-inline-block align-top mb-3" />
                <h2 style={{color:"white"}}>{process.env.REACT_APP_TITLE}</h2>
            </div>
            <form onSubmit={handleSubmit} className="login-form">
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    margin="normal"
                    label="Email"
                    type="text"
                    name="username"
                    value={username}
                    onChange={handleInputChange}
                />
                <TextField
                    fullWidth
                    autoComplete='off'
                    variant="outlined"
                    margin="normal"
                    label="Password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                />
                {getErrorMessage()}

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit-btn mt-3"
                    style={{ marginTop: "1rem" }}
                >
                    Submit
                </Button>

            </form>
        </Container>
    );
}

export default Login;
